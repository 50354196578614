<!-- =========================================================================================
  File Name: UserEditTabInformation.vue
  Description: User Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="MainInfo">
    <div>
      <div class="vx-row w-full">
        <div class="vx-col w-full lg:w-2/3">
          <div class="vx-row w-full">
            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("NameEn") }}</label>
              <vs-input
                v-model="ExpertModel.ApplicationUser.FullNameEn"
                class="w-full"
                :data-vv-as="$t('FullName')"
                name="FullName"
                v-validate="'required'"
              />
            </div>

            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("NameAr") }}</label>
              <vs-input
                v-model="ExpertModel.ApplicationUser.FullNameAr"
                class="w-full"
                :data-vv-as="$t('FullName')"
                name="FullName"
                v-validate="'required'"
              />
            </div>
          </div>

          <div class="vx-row w-full">
            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("Email") }}</label>
              <vs-input
                v-model="ExpertModel.ApplicationUser.Email"
                class="w-full"
                name="Email"
                :data-vv-as="$t('Email')"
                v-validate="'required'"
              />
            </div>

            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("PhoneNumber") }}</label>
              <vs-input
                v-model="ExpertModel.ApplicationUser.PhoneNumber"
                class="w-full"
                :data-vv-as="$t('PhoneNumber')"
                name="PhoneNumber"
                v-validate="'required'"
              />
            </div>
          </div>

          <div class="vx-row w-full">
            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("Address") }}</label>
              <vs-input
                v-model="ExpertModel.ApplicationUser.Address"
                class="w-full"
                :data-vv-as="$t('Address')"
                name="Address"
                v-validate="'required'"
              />
            </div>

        <div v-if="!ExpertModel.ID>0" class="vx-col lg:w-1/2">
          <label class="text-sm opacity-75">{{ $t("Password") }}</label>
          <vs-input
            v-model="ExpertModel.ApplicationUser.Password"
            type="password"
            :data-vv-as="$t('password')"
            class="w-full"
            name="password"
             v-validate="'required|min:6|max:25|lowCase|UpCase|SpecialChar|number'"
            :disabled="ExpertModel.ID>0"
          />
          <span class="text-danger text-sm" v-show="errors.has('password')">
             {{ errors.first("password")}}
            </span>
        </div>

            <div class="vx-col lg:w-1/2">
              <label class="text-sm opacity-75">{{ $t("Active") }}</label>
              <vs-checkbox
                v-model="ExpertModel.ApplicationUser.IsActive"
                :value="true"
              ></vs-checkbox>
            </div>
          </div>
        </div>

        <div class="vx-row w-full lg:w-1/3">
          <vs-card title="icon">
            <h4>{{ $t("Image") }}</h4>
            <template v-if="ExpertModel.ApplicationUser.ProfileImagePath">
              <div
                class="img-container w-64 mx-auto flex items-center justify-center"
              >
                <img
                  :src="baseURL + ExpertModel.ApplicationUser.ProfileImagePath"
                  alt="img"
                  class="responsive"
                />
              </div>
            </template>
            <div class="modify-img flex justify-between mt-5">
              <input
                type="file"
                class="hidden"
                ref="updateImgInput"
                @change="updateCurrImg"
                accept="image/*"
              />
              <vs-button
                class="mr-4"
                type="flat"
                @click="$refs.updateImgInput.click()"
                >{{ $t("UpdateImage") }}</vs-button
              >
              <vs-button
                type="flat"
                color="#999"
                @click="ExpertModel.ApplicationUser.ProfileImagePath = null"
                >{{ $t("RemoveImage") }}</vs-button
              >
            </div>
          </vs-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";

export default {
  components: {},
  props: {
    ExpertModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      baseURL: domain,
      isMail: false,
      DownUrl: domain,
      Attachment: null,
      update: false,
      ExpertStatus: [],
    };
  },
  computed: {},
  methods: {
    updateCurrImg(input) {
      this.$store.dispatch("updateCurrImg", input).then((response) => {
        this.ExpertModel.ApplicationUser.ProfileImagePath = response.data;
      });
    },
    SetExpertStatus() {
      if (localStorage.getItem("SaveLang") === "ar") {
        this.ExpertStatus = [
          { label: "نشط", value: true },
          { label: "غير نشط", value: false },
        ];
      } else {
        this.ExpertStatus = [
          { label: "Active", value: true },
          { label: "Inactive", value: false },
        ];
      }
    },
  },
  created() {},
};
</script>
