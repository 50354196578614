<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        v-for="item in ExpertModel.RateVideos"
        :key="item.ID"
      >
        <vx-card class="vxCardPatient text-center cursor-pointer rounded-xl">
          <div class="vx-col">
            <video
              :width="350"
              :height="200"
              id="videojs-sprite-thumbnails-player"
              class="video-js vjs-default-skin"
              data-setup='{"aspectRatio":"12:5"}'
              controls
            >
              <source :src="baseURL + item.URL" type="video/mp4" />
            </video>
            <h4 class="mb-2">
                {{ item.AppUser.ApplicationUser.FullName }}
              </h4>
              <u style="size:20px" class="mb-2" @click="GotoVideoDetails(item.WeeklyVideoID)">
              {{$t('GotoVideoDetails')}}
              </u>
          </div>

        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";

export default {
  components: {

  },
  props: {
    ExpertModel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: {
        IsPaging: true,
        PageNumber: 1,
        PageSize: 20,
      },
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      baseURL: domain,
      rowDataForDelete: {},
      activeConfirm: false,
    };
  },
  computed: {
   
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
  
  },

  methods: {
   
    GotoVideoDetails(videoID){
      this.$router.push({ name: 'Video_Edit', params:{ID:videoID} });
    },

  },
  destroyed() {
    window.onscroll = () => {};
  },
  created() {
    
  },
};
</script>

<style lang="scss">
